<template>
  <OneColumn style="background: #19173B; overflow: auto;">
    <template v-slot:ColumnContent>
      <div class="edit">
        <div class="container">
          <div class="head">
            <span class="OctaS_PageTitleDefault">Редактирование профиля</span>
          </div>
          <div>
            <!-- <form action="/upload" method="post" enctype="multipart/form-data">
          <label>Файл</label><br />
          <input type="file" name="filedata" /><br /><br />
          <input type="submit" value="Send" />
        </form> -->

            <!-- <hr class="hr0" /> -->
            <el-upload
              class="avatar-uploader"
              action="#"
              :auto-upload="false"
              :show-file-list="false"
              :on-change="handleAvatarSuccess"
            >
              <div
                v-if="imageUrl != null"
                :src="imageUrl"
                class="img-block"
                :style="
                  `background-image: url('${encodeURI(
                    imageUrl.replaceAll('\\', '/')
                  )}');`
                "
              ></div>
              <div v-else class="img-block img-block-default"></div>

              <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <img
            v-else
            class="img-card"
            src="../../assets/img/profile/user_icon.png"
          /> -->
              <div class="el-upload__text">Загрузить фото</div>
            </el-upload>
          </div>

          <div>
            <el-form :model="formEdit">
              <!-- Личная информация -->
              <div>
                <hr class="hr1" />

                <el-row :gutter="10" align="middle">
                  <!-- <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="2"> </el-col> -->
                  <el-col :xs="3" :sm="5" :md="4" :lg="4" :xl="5">
                    <div class="label-info">Имя <span>&nbsp;*</span></div>
                  </el-col>
                  <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="5">
                    <el-input v-model="formEdit.name"></el-input>
                  </el-col>
                  <el-col :xs="3" :sm="5" :md="4" :lg="4" :xl="5">
                    <div class="label-info">Отчество <span>&nbsp;*</span></div>
                  </el-col>
                  <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="5">
                    <el-input v-model="formEdit.patronymic"></el-input>
                  </el-col>
                  <!-- <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="2"></el-col> -->
                </el-row>

                <el-row :gutter="10" align="middle">
                  <!-- <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="2"></el-col> -->
                  <el-col :xs="3" :sm="5" :md="4" :lg="4" :xl="5">
                    <div class="label-info">Фамилия <span>&nbsp;*</span></div>
                  </el-col>
                  <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="5">
                    <el-input v-model="formEdit.surname"></el-input>
                  </el-col>
                  <el-col :xs="3" :sm="5" :md="4" :lg="4" :xl="5">
                    <div class="label-info">
                      Дата рождения <span>&nbsp;*</span>
                    </div>
                  </el-col>
                  <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="5">
                    <el-input type="date" v-model="formEdit.bd"></el-input>
                  </el-col>
                  <!-- <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="2"></el-col> -->
                </el-row>
              </div>

              <!-- Контакты -->
              <div>
                <hr class="hr2" />

                <el-row :gutter="10" align="middle">
                  <!-- <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="2"> </el-col> -->
                  <el-col :xs="3" :sm="5" :md="4" :lg="4" :xl="5">
                    <div class="label-info">
                      Электронная почта <span>&nbsp;*</span>
                    </div>
                  </el-col>
                  <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="5">
                    <el-input type="email" v-model="formEdit.email"></el-input>
                  </el-col>
                  <el-col :xs="3" :sm="5" :md="4" :lg="4" :xl="5">
                    <div class="label-info">Телефон <span>&nbsp;*</span></div>
                  </el-col>
                  <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="5">
                    <el-input type="tel" v-model="formEdit.tel"></el-input>
                  </el-col>
                  <!-- <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="2"></el-col> -->
                </el-row>

                <el-row :gutter="10" align="middle">
                  <!-- <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="2"></el-col> -->
                  <el-col :xs="3" :sm="5" :md="4" :lg="4" :xl="5">
                    <div class="label-info">
                      Ник в телеграмме <span>&nbsp;*</span>
                    </div>
                  </el-col>
                  <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="5">
                    <el-input type="url" v-model="formEdit.tg_nick"></el-input>
                  </el-col>
                  <el-col :xs="3" :sm="5" :md="4" :lg="4" :xl="5">
                    <div class="label-info">GitHub/GitLab</div>
                  </el-col>
                  <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="5">
                    <el-input v-model="formEdit.git"></el-input>
                  </el-col>
                  <!-- <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="2"></el-col> -->
                </el-row>
              </div>

              <!-- Место учебы -->
              <div>
                <hr class="hr3" />

                <el-row :gutter="10" align="middle">
                  <!-- <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="2"> </el-col> -->
                  <el-col :xs="3" :sm="5" :md="4" :lg="4" :xl="5">
                    <div class="label-info">Регион <span>&nbsp;*</span></div>
                  </el-col>
                  <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="5">
                    <div class="multipleFields">
                      <el-select
                        v-model="region_selected"
                        class="user-field form-control-dark mb-4"
                        placeholder="Выберите"
                        size="large"
                        style="width: 20rem;"
                      >
                        <el-option
                          v-for="item in region_list"
                          :key="item.name"
                          :label="item.name"
                          :value="item.name"
                          @click="setUniveristiesList(item.name)"
                        />
                      </el-select>

                      <input
                        v-if="region_selected == 'Другое'"
                        v-model="region_other"
                        type="text"
                        placeholder="Укажите название"
                        id="universities"
                        class="user-field form-control-dark mb-4"
                        style="width: 20rem"
                      />
                    </div>
                  </el-col>

                  <el-col :xs="3" :sm="5" :md="4" :lg="4" :xl="5">
                    <div class="label-info">Университет</div>
                  </el-col>
                  <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="5">
                    <div class="multipleFields">
                      <el-select
                        v-model="univesities_id"
                        class="user-field form-control-dark mb-4"
                        placeholder="Выберите"
                        size="large"
                        style="width: 20rem;"
                      >
                        <el-option
                          v-for="item in univesities_list"
                          :key="item.name"
                          :label="item.name"
                          :value="item.id"
                        />
                      </el-select>

                      <input
                        v-if="
                          univesities_list.find(
                            (obj) =>
                              obj.id == univesities_id && obj.name == 'Другое'
                          )
                        "
                        v-model="univesities_other"
                        type="text"
                        placeholder="Укажите название"
                        id="universities"
                        class="user-field form-control-dark mb-4"
                        style="width: 20rem"
                      />
                    </div>
                  </el-col>

                  <!-- <el-col :xs="1" :sm="1" :md="2" :lg="2" :xl="2"></el-col> -->
                </el-row>

                <!-- <el-row :gutter="10" align="middle">
         
              <el-col :xs="3" :sm="5" :md="4" :lg="4" :xl="5">
                <div class="label-info">Место работы</div>
              </el-col>
              <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="5">
                <el-input v-model="formEdit.work"></el-input>
              </el-col>
              <el-col :xs="3" :sm="5" :md="4" :lg="4" :xl="5">
                <div class="label-info">Должность</div>
              </el-col>
              <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="5">
                <el-input v-model="formEdit.pos"></el-input>
              </el-col>   
            </el-row> -->
              </div>
            </el-form>
          </div>
          <div class="save-btn">
            <ButtonGradientInsideElement
              @click.native="updateButton"
              ButtonText="Сохранить"
            />
            <!-- <el-button plain></el-button> -->
            <!-- <el-input type="button" @click="update" value="Сохранить"></el-input> -->
          </div>
        </div>
      </div>
    </template>
  </OneColumn>
</template>

<script>
import OneColumn from "@/components/ProfileTemplates/OneColumn.vue";
import ButtonGradientInsideElement from "@/components/CustomElements/ButtonGradient";

import {
  update,
  findById,
  upload,
  getRegionAndUniver,
  getRegionAndUniverList,
  getUserRegionAndUniver,
} from "@/api/user.js";
import { mapActions, mapMutations } from "vuex";

export default {
  data() {
    return {
      imageUrl: null,
      imageFile: null,
      formEdit: {
        name: "",
        surname: "",
        patronymic: "",
        email: "",
        bd: "",
        tel: "",
        tg_nick: "",
        git: "",
        city: "",
        work: "",
        pos: "",
        university_model_id: "",
        university_model_other: "",
        region_model_other: "",
      },

      region_list: [],
      region_selected: "",
      region_other: "",

      univesities_list: [],
      univesities_id: "",
      univesities_other: "",
    };
  },
  components: { OneColumn, ButtonGradientInsideElement },
  async created() {
    //console.log(this.$store.getters["auth/isLoggedIn"].id);
    /* this.id = this.$store.getters["auth/getUser"].id; */
    this.id = this.$store.getters["auth/isLoggedIn"].id;
    this.getUser(this.id);

    await getRegionAndUniverList().then((response) => {
      this.region_list = response.data;
    });
    /* var sendObject = Object.assign({}, this.formEdit, { id: this.id });
    console.log("sendObject: ", sendObject); */
  },
  methods: {
    ...mapActions("auth", ["changeUser"]),
    ...mapMutations("auth", ["SET_USER"]),
    handleAvatarSuccess(file) {
      console.log(file);
      this.imageFile = file.raw;
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("Фотография должна быть в формате JPG!");
      }
      if (!isLt2M) {
        this.$message.error("Объем фотографии не должен превышать 2MB!");
      }
      return isJPG && isLt2M;
    },
    updateButton() {
      this.formEdit.university_model_id = this.univesities_id;
      this.formEdit.university_model_other = this.univesities_other;
      this.formEdit.region_model_other = this.region_other;

      //console.log("submit!");
      const formData = new FormData();
      if (this.imageFile) {
        formData.append("file", this.imageFile, this.imageFile.name);
      } else {
        formData.append("file", null);
      }
      for (let key in this.formEdit) {
        if (this.formEdit[key] !== null) {
          formData.append(key, this.formEdit[key]);
        }
      }
      update(this.id, formData).then(() => {
        this.$message({
          showClose: true,
          message: "Изменения сохранены!",
          type: "success",
        });
        this.getUser(this.id);
      });
    },
    uploadFoto() {
      //console.log("submit!");
      upload(this.id, this.imageUrl).then();
    },
    getUser(id) {
      findById(id).then((response) => {
        this.getUniverAndRegion(response.data.id);

        this.formEdit.name = response.data.name;
        this.formEdit.patronymic = response.data.patronymic;
        this.formEdit.surname = response.data.surname;
        this.formEdit.bd = response.data.bd
          ? response.data.bd.slice(0, 10)
          : response.data.bd;
        this.formEdit.email = response.data.email;
        this.formEdit.tel = response.data.tel;
        this.formEdit.tg_nick = response.data.tg_nick;
        this.formEdit.git = response.data.git;
        this.formEdit.city = response.data.city;
        this.formEdit.work = response.data.work;
        this.formEdit.pos = response.data.pos;
        this.imageUrl = response.data.url_img;

        this.SET_USER(response.data);
      });
    },

    async getUniverAndRegion(user_id) {
      await getUserRegionAndUniver(user_id).then((response) => {
        this.setRegionAndUniverForUser(response.data.MapRegion);

        return;
      });
    },

    setUniveristiesList(selectedRegion) {
      this.univesities_list = [];
      this.univesities_id = "";

      let result = this.region_list.find((obj) => obj.name == selectedRegion);
      this.univesities_list = [...result.univers];
    },

    setRegionAndUniverForUser(MapUniver) {
      if (MapUniver == null || MapUniver?.UsersChoosedUniver == null) {
        return 0;
      }

      this.region_selected = MapUniver.UsersChoosedUniver.region.name;
      this.region_other =
        this.region_selected == "Другое" ? MapUniver.region_other : "";

      this.setUniveristiesList(MapUniver.UsersChoosedUniver.region.name);

      this.univesities_id = MapUniver.UsersChoosedUniver.id;
      this.univesities_other =
        MapUniver.UsersChoosedUniver.name == "Другое"
          ? MapUniver.univer_other
          : "";
    },
  },
  mounted() {
    // console.log("mounted");
    //console.log(this.$store.getters["auth/isLoggedIn"].id);
    //this.getUser(this.$store.getters["auth/isLoggedIn"].id);
  },
};
</script>

<style scoped>
.edit .el-input {
  background-color: transparent !important;
  margin: auto !important;
  font-family: "Montserrat" !important;
  color: #2c3e50 !important;
  border-radius: 10px !important;
}
.edit .el-input:deep(.el-input__inner) {
  padding: 0px 13px !important;
  background: linear-gradient(
    268.95deg,
    rgba(41, 37, 88, 0.536345) 4.06%,
    rgba(38, 35, 83, 0.866791) 42.35%,
    rgba(40, 38, 89, 0.50218) 99.53%
  ) !important;
  border: 1px solid #413e74;
  border-radius: 4px !important;
  color: #e8e7ec !important;

  height: auto !important;
  font-size: 0.9rem !important;
}
#universities {
  padding: 13px !important;
  background: linear-gradient(
    268.95deg,
    rgba(41, 37, 88, 0.536345) 4.06%,
    rgba(38, 35, 83, 0.866791) 42.35%,
    rgba(40, 38, 89, 0.50218) 99.53%
  ) !important;
  border: 1px solid #413e74;
  border-radius: 4px !important;
  color: #e8e7ec !important;

  width: 100% !important;
  height: auto !important;
  font-size: 0.9rem !important;
}

.edit .el-select {
  margin-bottom: 0 !important;
  width: 100% !important;
}
.edit .el-select:deep(.el-input) {
  background-color: transparent !important;
}

.edit .el-select:deep(.el-input__inner) {
  height: auto !important;
  font-size: 0.9rem !important;
  padding: 0px 13px !important;
  padding-top: 13px;
  padding-bottom: 13px;
  background: linear-gradient(
    268.95deg,
    rgba(41, 37, 88, 0.536345) 4.06%,
    rgba(38, 35, 83, 0.866791) 42.35%,
    rgba(40, 38, 89, 0.50218) 99.53%
  ) !important;
  border: 1px solid #413e74;
  border-radius: 4px !important;
  color: #e8e7ec !important;
}

.multipleFields {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
}

.img-block {
  position: relative;

  width: 400px;
  height: 300px;

  margin: auto;
  margin-bottom: 4vh;

  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 400px auto;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;

  border-radius: 12px;
}
.img-block-default {
  background-size: 150px auto;
  background-image: url("../../assets/img/profile/user_icon.png");
}
.img-block-default:hover {
  background-size: 180px auto !important;
}

.img-block:hover {
  background-size: 430px auto;
}

.el-col {
  display: flex;
  justify-content: flex-end !important;
  margin: 5px 10px;
}
.avatar-uploader {
  display: flex;
  width: 100%;
  justify-content: center;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #2c3e506b;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.edit {
  position: relative;
  display: flex;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat" !important;
}

.head {
  display: flex;
  align-items: center;
}
h3 {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 32px;
  font-weight: 700 !important;
  line-height: 36px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #ffffff !important;
}

.container {
  display: flex;
  flex-direction: column;
  padding-bottom: 40px !important;
  width: 100%;
  max-width: 100%;
}

.el-row {
  margin: 10px !important;
}
.save-btn {
  margin: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 2vh;
}
.el-button {
  width: 20vh;
  margin: 5px;
  background-color: #d64a4a;
  color: rgb(255, 255, 255) !important;
  padding: 0.2rem 1rem;
  font-size: 0.8rem;
  border-radius: 20px !important;
  border: none !important;
  font-family: "Montserrat" !important;
}

.el-button:hover {
  background-color: #d64a4a !important;
  opacity: 0.9 !important;
  color: #fff !important;
  padding: 0.5rem 1rem !important;
  border: none !important;
}

.el-input {
  margin: auto;
  font-family: "Montserrat" !important;
  color: #2c3e50 !important;
  background-color: rgb(255, 255, 255) !important;
  border-radius: 10px !important;
}
.hr0 {
  border: 0;
  height: 0px;
  overflow: visible;
  padding: 0;
  color: rgb(71, 71, 71);
  text-align: left;
}
.hr0::after {
  content: "Фотография";
  display: inline-block;
  position: relative;
  top: -0.8em;
  font-size: 1.1em;
  padding: 0 1em;
  background: rgb(248, 247, 247);
}
.hr1 {
  border: 0;
  height: 0px;
  overflow: visible;
  padding: 0;
  color: #474747;
  text-align: left;
  font-size: 1.1rem !important;
  opacity: 0.7;
}
.hr1::after {
  content: "Личная информация";
  display: inline-block;
  position: relative;

  top: -0.8em;
  font-size: 1.1em;
  padding: 0 1em;
  background: none;
  color: #fff;
}
.hr2 {
  border: 0;
  height: 0px;
  overflow: visible;
  padding: 0;
  color: rgb(71, 71, 71);
  text-align: left;
  font-size: 1.1rem !important;
  opacity: 0.7;
}
.hr2::after {
  content: "Контакты";
  display: inline-block;
  position: relative;

  top: -0.8em;
  font-size: 1.1em;
  padding: 0 1em;
  background: none;
  color: #fff;
}

.hr3 {
  border: 0;
  height: 0px;
  overflow: visible;
  padding: 0;
  color: rgb(71, 71, 71);
  text-align: left;
  font-size: 1.1rem !important;
  opacity: 0.7;
}
.hr3::after {
  content: "Место учебы";
  display: inline-block;
  position: relative;

  top: -0.8em;
  font-size: 1.1em;
  padding: 0 1em;
  background: none;
  color: #fff;
}

.el-upload__text {
  font-family: "Montserrat" !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 1.2rem !important;
  line-height: 1.2rem !important;
  color: #e8e7ec !important;
}

.img-card {
  padding: 20px 20px;
  width: 100%;
  /* margin-bottom: 25px; */
  background-size: contain;
}

.label-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  font-size: 0.9rem !important;
  color: silver;
}

hr {
  margin: 0;
  height: fit-content !important;
  background-color: transparent;
}
hr::after {
  top: 0 !important;
}
</style>

<style scoped>
@media (max-width: 480px) {
  h3 {
    font-size: 1rem !important;
    margin-bottom: 3rem !important;
  }

  .edit > .container > .head {
    justify-content: center !important;
  }
  .edit > .container .el-form > div > hr {
    font-size: 1rem !important;
    text-align: center !important;
    margin-top: 5rem !important;
    padding-bottom: 1rem !important;
    background-color: transparent !important;
  }
  .edit > .container .el-form > div > div .label-info {
    font-size: 0.76rem !important;
  }
  .edit > .container {
    width: 100% !important;
    max-width: 1400px !important;
    padding: 0 !important;
    padding-bottom: 100px !important;
  }

  .edit > .container .el-form > div > .el-row:nth-child(2) {
    margin-bottom: 0 !important;
  }
  .edit > .container .el-form > div > .el-row:nth-child(3) {
    margin-top: 0 !important;
  }

  .edit > .container .el-form > div > div > .el-col {
    justify-content: flex-start !important;
    margin-top: 0 !important;
    padding-top: 0 !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    width: 100% !important;
    min-width: 100%;
  }

  .edit > .container .el-form > div > div {
    display: flex !important;
    flex-direction: column !important;
  }

  .edit > .container .el-form > div > div .label-info,
  .edit > .container .el-form > div > div input {
    font-size: 0.76rem !important;
  }
  .edit > .container .el-form > div > div .label-info {
    margin-top: 1.6rem;
  }
}
@media (min-width: 480px) and (max-width: 768px) {
  .edit > .container > .head {
    justify-content: center !important;
  }
  .edit > .container .el-form > div > hr {
    font-size: 1rem !important;
    text-align: center !important;
    margin-top: 5rem !important;
    padding-bottom: 1rem !important;
    background-color: transparent !important;
  }
  .edit > .container .el-form > div > div .label-info {
    font-size: 0.76rem !important;
  }
  .edit > .container {
    width: 100% !important;
    max-width: 1400px !important;
  }

  .edit > .container .el-form > div > .el-row:nth-child(2) {
    margin-bottom: 0 !important;
  }
  .edit > .container .el-form > div > .el-row:nth-child(3) {
    margin-top: 0 !important;
  }

  .edit > .container .el-form > div > div > .el-col {
    justify-content: flex-start !important;
    margin-top: 0 !important;
    padding-top: 0 !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    width: 400px;
    max-width: 400px !important;
  }

  .edit > .container .el-form > div > div {
    display: flex !important;
    flex-direction: column !important;
  }

  .edit > .container .el-form > div > div .label-info,
  .edit > .container .el-form > div > div input {
    font-size: 0.76rem !important;
  }
  .edit > .container .el-form > div > div .label-info {
    margin-top: 1.6rem;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .edit > .container > .head {
    justify-content: center !important;
  }
  .edit > .container .el-form > div > hr {
    font-size: 1rem !important;
    text-align: center !important;
    margin-top: 5rem !important;
    padding-bottom: 1rem !important;
    background-color: transparent !important;
  }
  .edit > .container .el-form > div > div .label-info {
    font-size: 0.76rem !important;
  }
  .edit > .container {
    width: 100% !important;
    max-width: 1400px !important;
  }

  .edit > .container .el-form > div > .el-row:nth-child(2) {
    margin-bottom: 0 !important;
  }
  .edit > .container .el-form > div > .el-row:nth-child(3) {
    margin-top: 0 !important;
  }

  .edit > .container .el-form > div > div > .el-col {
    justify-content: flex-start !important;
    margin-top: 0 !important;
    padding-top: 0 !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    width: 400px;
    max-width: 400px !important;
  }

  .edit > .container .el-form > div > div {
    display: flex !important;
    flex-direction: column !important;
  }

  .edit > .container .el-form > div > div .label-info,
  .edit > .container .el-form > div > div input {
    font-size: 0.76rem !important;
  }
  .edit > .container .el-form > div > div .label-info {
    margin-top: 1.6rem;
  }
}
@media (min-width: 992px) and (max-width: 1400px) {
  .edit > .container > .head {
    justify-content: center !important;
  }
  .edit > .container .el-form > div > hr {
    font-size: 1rem !important;
    text-align: center !important;
    margin-top: 5rem !important;
    padding-bottom: 1rem !important;
    background-color: transparent !important;
  }
  .edit > .container .el-form > div > div .label-info {
    font-size: 0.76rem !important;
  }
  .edit > .container {
    width: 100% !important;
    max-width: 1400px !important;
  }
}
@media (min-width: 1400px) and (max-width: 1600px) {
}
@media (min-width: 1600px) {
}
</style>
