<template>
  <OneColumn style="background: #19173B; overflow: auto;">
    <template v-slot:ColumnContent>
      <div class="about">
        <div class="container">
          <div class="head">
            <div class="left-side">
              <h3>Прогресс обучения</h3>
              <span class="badge badge-default">{{ section.name }}</span>
            </div>
          </div>
          <div class="progress">
            <el-progress
              class="AboutProgressLine"
              type="line"
              :text-inside="true"
              :stroke-width="18"
              :percentage="userProgress"
              color="#21b85d"
            >
              <div v-bind:class="{ progressText: userProgress <= 10 }">
                {{ userProgress }} %
              </div>
            </el-progress>
          </div>
          <LevelCard class="levelCards" :levels="levels"></LevelCard>
        </div>
      </div>
    </template>
  </OneColumn>
</template>

<script>
import OneColumn from "@/components/ProfileTemplates/OneColumn.vue";

import LevelCard from "@/components/Edu/LevelCard.vue";
import { getSection } from "@/api/competence.js";
import { mapGetters } from "vuex";
import { findById } from "@/api/user.js";
import { createExtern } from "@/api/extern.js";
export default {
  data() {
    return {
      dialogExtern: false,
      levels: [],
      section: {
        name: "BI",
      },
      formExtern: {
        direction_id: null,
        description: "",
        file: null,
      },
    };
  },
  components: { LevelCard, OneColumn },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),
    userProgress: function() {
      let userLevels = [];
      let sectionLevels = [];
      let intersection = [];
      this.user.user_cells.forEach((element) => {
        userLevels.push(element["level_id"]);
      });
      this.levels.forEach((element) => {
        sectionLevels.push(element["id"]);
      });
      intersection = sectionLevels.filter((x) => userLevels.includes(x));
      return Math.round((intersection.length / sectionLevels.length) * 100, 0);
    },
  },
  created() {
    getSection(this.$route.params.section).then((response) => {
      let countL = 1;
      response.data.degrees.sort((a, b) => b.id - a.id);
      for (let i = 0; i < response.data.degrees.length; i++) {
        response.data.degrees[i].levels.sort((a, b) => a.id - b.id);
        for (let j = 0; j < response.data.degrees[i].levels.length; j++) {
          this.levels.push(
            Object.assign(response.data.degrees[i].levels[j], { level: countL })
          );
          countL++;
        }
      }
      this.section = response.data;
    });
  },
  methods: {
    handleRemove(file, fileList) {
      this.formExtern.file = null;
    },
    handleSuccess(file, fileList) {
      this.formExtern.file = {
        url: URL.createObjectURL(file.raw),
        file: file.raw,
        name: file.name,
      };
    },
    openDialog() {
      this.formExtern = {
        direction_id: this.section.id,
        description: this.formExtern.description,
        file: null,
      };
      this.dialogExtern = true;
    },
    addExtern() {
      const formData = new FormData();
      if (this.formExtern.file) {
        formData.append(
          "file",
          this.formExtern.file.file,
          this.formExtern.file.name
        );
      } else {
        formData.append("file", null);
      }
      formData.append("description", this.formExtern.description);
      formData.append("direction_id", this.formExtern.direction_id);
      formData.append("user_id", this.user.id);
      createExtern(formData)
        .then(() => {
          findById(this.user.id).then((response) => {
            this.SET_USER(response.data);
          });
          this.dialogExtern = false;
          this.$message({
            type: "success",
            message: `Заявка отправлена!`,
          });
        })
        .catch((e) => {
          this.$message({
            type: "error",
            message: `Произошла ошибка`,
          });
        });
    },
  },
};
</script>

<style scoped>
.levelCards:deep(.el-card) {
  background-color: rgba(32, 30, 72, 0.7) !important;
  border-radius: var(--o-s-global-border-radius) !important;

  box-shadow: 8px 0 8px rgba(31, 18, 35, 0.15) !important;
  border: none;
}
.levelCards:deep(.el-card .card-header) {
  color: #e8e7ec !important;
  font-weight: 600 !important;
  font-size: 1.2rem !important;
  line-height: 1.2rem !important;
}
.levelCards:deep(.el-card .sectionName) {
  color: rgb(200, 195, 222) !important;
  font-weight: 400;
}
.levelCards:deep(.el-card > .el-card__header) {
  border-color: rgba(232, 231, 236, 0.2);
}

.levelCards:deep(.el-card
    .el-card__body
    .section-item
    a
    .right-side
    > div:first-child) {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
}

.AboutProgressLine {
  background-color: #312e57;
}

.AboutProgressLine,
.AboutProgressLine:deep(.el-progress-bar),
.AboutProgressLine:deep(.el-progress-bar__outer) {
  height: 100%;
}

.AboutProgressLine:deep(.el-progress-bar__outer) {
  background-color: #312e57;
  height: 18px !important;
}

.AboutProgressLine:deep(.progressText) {
  color: #fff !important;
}

.formExternDesc {
  text-align: start;
  word-break: normal;
}
.formExtern {
  padding: 0px 10px !important;
}
.btn-extern {
  font-weight: 500;
  color: black;
  border-radius: 10px;
  margin-right: 12px;
  font-size: 0.9rem;
  border: 1px solid black;
  font-family: "Montserrat";
  font-weight: 600;
  background-color: white;
}
i {
  font-size: 0.9rem;
}
.btn-extern:hover {
  /* font-weight: 500; */
  background-color: #21b85d !important;
}
.left-side {
  display: flex;
  align-items: center;
}
.progress {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 50px;
  height: 100%;
  border-radius: 20px;
}
.progressText {
  color: black !important;
}
.badge {
  margin-bottom: 5px !important;
  font-size: 0.9rem;
  margin-left: 15px;
  background-color: #0cae6a !important;
}
.head {
  margin-top: 30px !important;
  margin-bottom: 15px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
.about {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;
}

h3 {
  color: black !important;
}
.container {
  display: flex;
  flex-direction: column;
  padding-bottom: 40px !important;
  width: 100%;
  max-width: 100%;
}
</style>

<style scoped>
@media (max-width: 480px) {
  .about > .container {
    width: 100%;
  }

  .about > .container .left-side {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .about > .container .left-side > span {
    margin: auto;
  }
}
@media (min-width: 480px) and (max-width: 768px) {
  .about > .container {
    width: 90%;
  }
  .about > .container .left-side {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
}

@media (max-width: 992px) {
  .about > .container {
    margin-bottom: 100px;
  }
}
</style>
